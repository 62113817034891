<template>
  <div>
    <b-row>
      <b-col
        md="6"
        lg="5"
        xl="5"
      >
        <h5 class="mb-2">
          {{ $t('widgets.type') }}
        </h5>
        <b-form-group
          :label="$t('campaigns.type')"
          label-for="i-type"
        >
          <b-form-select
            :value="type"
            :options="types"
            @change="$emit('changeWidgetModel', [$event, 'type'])"
          />
        </b-form-group>
        <b-card class="builder-params">
          <div class="text-center mb-2">
            <b-form-radio-group
              id="type-params"
              v-model="builder.typeParams"
              button-variant="outline-primary"
              :options="typeParamsLocal"
              buttons
              name="radios-btn-default"
            />
          </div>
          <div v-if="builder.typeParams === 'main'">
            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label-cols-xl="4"
              :label="$t('widgets.widgettitle')"
              label-for="block_name"
            >
              <b-form-input
                id="block_name"
                v-model="widget.title"
                :placeholder="$t('widgets.widgettitle')"
              />
            </b-form-group>
            <b-form-group
              v-if="authUserData.role.includes('admin')"
              label-cols="12"
              label-cols-lg="3"
              label-cols-xl="4"
              :label="$t('widgets.source_title')"
              label-for="i-block_source_title"
            >
              <b-form-input
                id="i-block_source_title"
                v-model="blockData.block_source_title"
                :placeholder="$t('widgets.source_title')"
              />
            </b-form-group>
            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label-cols-xl="4"
              :label="$t('builder.block_type')"
              label-for="block_type"
            >
              <b-form-select
                id="block_type"
                v-model="blockData.type"
                :options="blockType"
              />
            </b-form-group>
            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label-cols-xl="4"
              :label="$t('builder.block_format')"
              label-for="blockFormat"
            >
              <b-form-select
                id="blockFormat"
                v-model="builder.blockFormat"
                :options="filtredFormat"
              />
            </b-form-group>
            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label-cols-xl="4"
              :label="$t('builder.configuration')"
              label-for="rows"
              class="align-items-end mb-1"
            >
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    :label="$t('builder.rows')"
                    label-for="rows"
                  >
                    <b-form-select
                      id="rows"
                      v-model="blockData.rows"
                      :options="builder.rowsColsOptions"
                      :disabled="blockData.type === 1"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="$t('builder.cols')"
                    label-for="columns"
                  >
                    <b-form-select
                      id="columns"
                      v-model="blockData.cols"
                      :options="builder.rowsColsOptions"
                      :disabled="blockData.type === 1"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label-cols-xl="4"
              :label="$t('builder.img_wide')"
              label-for="img_wide"
            >
              <b-form-select
                id="img_wide"
                v-model="blockData.img_wide"
                :options="pictureType"
              />
            </b-form-group>
            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label-cols-xl="4"
              :label="$t('builder.adaptation_type')"
              label-for="adaptation_type"
            >
              <b-form-select
                id="adaptation_type"
                v-model="blockData.adaptation_type"
                :options="adaptationType"
              />
            </b-form-group>
            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label-cols-xl="4"
              :label="$t('campaigns.status')"
              label-for="widget_status"
            >
              <b-form-select
                id="widget_status"
                :value="status"
                :options="statuses"
                @change="$emit('changeWidgetModel', [$event, 'status'])"
              />
            </b-form-group>
          </div>
          <div v-else>
            <h4 class="text-primary">
              {{ $t('builder.block_setup') }}
            </h4>
            <hr class="border-top-secondary">
            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label-cols-xl="4"
              :label="$t('builder.unit')"
            >
              <b-row class="mb-1">
                <b-col cols="12">
                  <label for="table_width">{{ $t('builder.width') }}</label>
                </b-col>
                <b-col cols="6">
                  <b-form-input
                    id="table_width"
                    v-model="blockData.table_width"
                    type="number"
                  />
                </b-col>
                <b-col cols="6">
                  <b-form-select
                    id="table_width_units"
                    v-model="blockData.table_width_units"
                    :options="[{ value: 1, text: '%' }, { value: 0, text: 'px' }]"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    :label="$t('builder.table_bg_color')"
                    label-for="blockData.table_bg_color"
                  >
                    <b-form-input
                      id="table_bg_color"
                      :value="blockData.table_bg_color"
                      type="color"
                      @change="blockData.table_bg_color = $event"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
            <hr class="border-top-gray">
            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label-cols-xl="4"
              :label="$t('builder.picture')"
              class="mb-3"
            >
              <b-row class="mb-1">
                <b-col cols="6">
                  <label for="image_width">{{ $t('builder.size') }} (px)</label>
                  <b-form-select
                    id="image_width"
                    v-model="blockData.img_width"
                    :options="builder.imageWidths.map(iw => ({value: iw, text: iw}))"
                  />
                </b-col>
                <b-col cols="6">
                  <label for="image_height">Height (px)</label>
                  <b-form-input
                    id="image_height"
                    v-model="blockData.block_img_max_height"
                    type="number"
                  />
                  <small>0 - auto</small>
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="12">
                  <label for="frame_type">{{ $t('builder.border_type') }}</label>
                  <b-form-select
                    id="frame_type"
                    v-model="blockData.img_border_type"
                    :options="builder.typeBorderOptions"
                  />
                </b-col>
              </b-row>
              <b-row class="mb-1">
                <b-col cols="6">
                  <label for="img_border_size">{{ $t('builder.border') }}</label>
                  <b-form-input
                    id="img_border_size"
                    v-model="blockData.img_border_size"
                    type="number"
                    min="1"
                    max="10"
                  />
                </b-col>
                <b-col cols="6">
                  <label for="img_border_color">{{ $t('builder.border_color') }}</label>
                  <b-form-input
                    id="img_border_color"
                    :value="blockData.img_border_color"
                    type="color"
                    @change="blockData.img_border_color = $event"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <label for="img_border_radius">{{ $t('builder.img_border_radius') }}</label>
                  <b-form-input
                    id="img_border_radius"
                    v-model="blockData.img_border_radius"
                    type="number"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <label for="img_text_indent">
                    {{ $t('builder.img_text_indent') }}
                    <feather-icon
                      v-b-tooltip.hover.top="$t('builder.img_text_indent_help')"
                      icon="InfoIcon"
                    />
                  </label>
                  <b-form-input
                    id="img_text_indent"
                    v-model="blockData.img_text_indent"
                    type="number"
                  />
                </b-col>
              </b-row>
            </b-form-group>
            <h4 class="text-primary">
              {{ $t('builder.text') }}
            </h4>
            <hr class="border-top-secondary">
            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label-cols-xl="4"
              :label="$t('builder.description')"
            >
              <b-row class="mb-1">
                <b-col cols="12">
                  <label for="desc_font">{{ $t('builder.font') }}</label>
                </b-col>
                <b-col cols="12 d-flex">
                  <b-form-select
                    id="desc_font"
                    v-model="blockData.desc_font"
                    :options="[{value: '', text: 'Site font'}, ...builder.fonts.map(font => ({value: font, text: font}))]"
                  />

                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="blockData.desc_font_weight === 'normal' ? 'outline-primary' : 'primary'"
                    class="btn-icon btn-desc-font"
                    @click="blockData.desc_font_weight === 'normal' ? blockData.desc_font_weight = 'bold' : blockData.desc_font_weight = 'normal'"
                  >
                    B
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="blockData.desc_text_decorat === 0 ? 'outline-primary' : 'primary'"
                    class="btn-icon btn-desc-font"
                    @click="blockData.desc_text_decorat === 0 ? blockData.desc_text_decorat = 1 : blockData.desc_text_decorat = 0"
                  >
                    <feather-icon icon="UnderlineIcon" />
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <label for="desc_font__size">{{ $t('builder.size') }}</label>
                  <b-form-input
                    id="desc_font_size"
                    v-model="blockData.desc_font_size"
                    type="number"
                  />
                </b-col>
                <b-col cols="6">
                  <label for="desc_font_color">{{ $t('builder.color') }}</label>
                  <b-form-input
                    id="desc_font_color"
                    :value="blockData.desc_font_color"
                    type="color"
                    @change="blockData.desc_font_color = $event"
                  />
                </b-col>
              </b-row>
            </b-form-group>
            <hr class="border-top-gray">
            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label-cols-xl="4"
              :label="$t('builder.location')"
              class="mb-3"
            >
              <b-form-select
                id="desc_font"
                v-model="blockData.desc_position"
                :options="descLocation"
              />
            </b-form-group>
            <h4 class="text-primary">
              {{ $t('builder.spreadsheet_cells') }}
            </h4>
            <hr class="border-top-secondary">
            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label-cols-xl="4"
              :label="$t('builder.indentation')"
            >
              <b-row class="mb-1">
                <b-col cols="6">
                  <label for="table_cellspacing_h">{{ $t('builder.horizontal') }}</label>
                  <b-form-input
                    id="table_cellspacing_h"
                    v-model="blockData.table_cellspacing_h"
                    type="number"
                  />
                </b-col>
                <b-col cols="6">
                  <label for="table_cellspacing_v">{{ $t('builder.vertical') }}</label>
                  <b-form-input
                    id="table_cellspacing_v"
                    v-model="blockData.table_cellspacing_v"
                    type="number"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <label for="td_padding">{{ $t('builder.padding') }}</label>
                  <b-form-input
                    id="td_padding"
                    v-model="blockData.td_padding"
                    type="number"
                  />
                </b-col>
                <b-col
                  sm="6"
                >
                  <b-form-group
                    :label="$t('builder.cells_color')"
                    label-for="blockData.td_bg_color"
                  >
                    <b-form-input
                      id="td_bg_color"
                      :value="blockData.td_bg_color"
                      type="color"
                      @change="blockData.td_bg_color = $event"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
            <hr class="border-top-gray">
            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label-cols-xl="4"
              :label="$t('builder.table_cells_border')"
              class="mb-3"
            >
              <b-row class="mb-1">
                <b-col cols="12">
                  <b-form-select
                    id="td_border_type"
                    v-model="blockData.td_border_type"
                    :options="builder.typeBorderOptions"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <label for="td_border_size">{{ $t('builder.border') }}</label>
                  <b-form-input
                    id="td_border_size"
                    v-model="blockData.td_border_size"
                    type="number"
                    min="1"
                    max="10"
                  />
                </b-col>
                <b-col cols="6">
                  <label for="td_border_color">{{ $t('builder.color') }}</label>
                  <b-form-input
                    id="td_border_color"
                    :value="blockData.td_border_color"
                    type="color"
                    @change="blockData.td_border_color = $event"
                  />
                </b-col>
              </b-row>
            </b-form-group>
          </div>
        </b-card>
      </b-col>
      <b-col
        md="6"
        lg="7"
        xl="7"
      >
        <BuilderPreview :block-data="blockData" />
      </b-col>
    </b-row>
    <!-- </b-card> -->

  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import BuilderPreview from '@/views/dashboard/widgets/WidgetBuilderPreview.vue'
import { getUserData } from '@/auth/utils'
import {
  BRow,
  BCol,
  BCard,
  BFormRadioGroup,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'WidgetBuilder',
  components: {
    BCard,
    BRow,
    BCol,
    BFormRadioGroup,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BuilderPreview,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: '',
    },
    types: {
      type: Array,
      required: false,
      default: null,
    },
    status: {
      type: String,
      required: true,
    },
    statuses: {
      type: Array,
      required: true,
    },
    widget: {
      type: Object,
      default: null,
    },
    widgetSettings: {
      type: Object,
      default: null,
    },
    currentTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      account: {},
      site: [],
      breadcrumbItems: [],
      authUserData: getUserData(),
      defaultBlockData: {
        name: '',
        block_source_title: '',
        block_img_max_height: 0,
        type: 0,
        rows: 3,
        cols: 3,
        adaptation_type: 2,
        fixed: 0,
        table_padding: 0,
        text_align: 'left',
        table_width: 100,
        table_width_units: 1,
        table_height: 0,
        table_height_units: 0,
        table_bg_color: '#ffffff',
        table_cellspacing_h: 5,
        table_cellspacing_v: 5,
        td_padding: 1,
        td_bg_color: '#ffffff',
        td_border_size: 0,
        td_border_type: 'solid',
        td_border_color: '#cccccc',
        td_border_radius: 0,
        img_wide: 1,
        img_width: 180,
        img_border_type: 'solid',
        img_border_size: 0,
        img_border_color: '#ffffff',
        img_text_indent: 5,
        img_border_radius: 0,
        desc_font: 'Sans-serif',
        desc_font_weight: 'bold',
        desc_text_padding: 5,
        desc_text_decorat: 0,
        desc_font_size: 15,
        desc_font_color: '#000000',
        desc_position: 1,
        style: '',
      },
      blockDataPreset: null,
      builder: {
        typeParams: 'main',
        typeParamsOptions: [
          { value: 'main', text: 'builder.typeMain' },
          { value: 'detailed', text: 'builder.typeDetailed' },
        ],
        blockTypeOptions: [
          { value: 0, text: 'builder.types.native' },
          { value: 1, text: 'builder.types.amp' },
        ],
        pictureTypeOptions: [
          { value: 0, text: 'builder.img_types.square' },
          { value: 1, text: 'builder.img_types.rectangular' },
        ],
        adaptationTypeOptions: [
          { value: 0, text: 'builder.adaptation_types.no' },
          { value: 1, text: 'builder.adaptation_types.hide' },
          { value: 2, text: 'builder.adaptation_types.wrap' },
        ],
        blockFormat: '',
        formats: [
          {
            type: 0,
            format_id: 0,
            params: {
              img: {
                wide: '1',
                width: '300',
                border: {
                  size: 0,
                  type: 'solid',
                  color: 'transparent',
                  radius: '0',
                },
                textIndent: '5',
              },
              desc: {
                font: {
                  name: 'Sans-serif',
                  size: '14',
                  color: '000000',
                  weight: 'bold',
                },
                text: {
                  decoration: 'none',
                },
                style: '{\r\n    line-height:1.2;\r\n    padding:2px 0 0 0;\r\n    -webkit-font-smoothing:antialiased;\r\n}',
              },
              text: {
                align: 'left',
                padding: '5',
                position: '1',
              },
              fixed: '0',
              table: {
                cell: {
                  color: 'FFFFFF',
                  style: '',
                  border: {
                    size: '0',
                    type: 'dashed',
                    color: '',
                    radius: '0',
                  },
                  padding: '1',
                },
                rows: '2',
                style: '{\r\n    float:left;\r\n    margin:.25em 1em .25em 0;\r\n    padding-bottom:.75em;\r\n}\r\n.pnx-div::before{\r\n    content:\'\';\r\n    font-size:16px;\r\n    font-family:sans-serif;\r\n    font-weight:bold;\r\n    text-transform:uppercase;\r\n    color:#000000;\r\n    display:block;\r\n    padding-bottom:.5em;\r\n    border-bottom:1px solid #E9EBEE;\r\n    margin-bottom:.75em;\r\n}\r\n.pnx-div::after{\r\n    content:\'\';\r\n    display:block;\r\n    clear:both;\r\n    width:100%;\r\n    padding-bottom:.75em;\r\n    border-bottom:1px solid #E9EBEE;\r\n    margin-bottom:.75em;\r\n}',
                width: '100',
                height: '',
                margin: ['', '', '', ''],
                bgColor: '',
                columns: '3',
                padding: ['', '', '', ''],
                widthType: '1',
                heightType: '1',
                cellspacing_v: '5',
                cellspacing_h: '5',
              },
              adaptation: '2',
            },
          },
          {
            type: 0,
            format_id: 1,
            params: {
              img: {
                wide: '1',
                width: '200',
                border: {
                  size: '0',
                  type: 'solid',
                  color: 'ffffff',
                  radius: '0',
                },
                textIndent: '5',
              },
              desc: {
                font: {
                  name: 'Sans-serif',
                  size: '17',
                  color: '555555',
                  weight: 'bold',
                },
                text: {
                  decoration: 'none',
                },
                style: '{\r\n    line-height:1.1;\r\n    padding: 2px 0 0 0;\r\n    -webkit-font-smoothing:antialiased;\r\n}',
              },
              text: {
                align: 'left',
                padding: '5',
                position: '1',
              },
              fixed: '0',
              table: {
                cell: {
                  color: 'F5F5F5',
                  style: '',
                  border: {
                    size: '0',
                    type: 'solid',
                    color: 'e5e5e5',
                    radius: '0',
                  },
                  padding: '15',
                },
                rows: '2',
                style: '',
                width: '100',
                height: '0',
                margin: ['0', '0', '0', '0'],
                bgColor: 'F5F5F5',
                columns: '2',
                padding: ['0', '0', '0', '0'],
                widthType: '1',
                heightType: '1',
                cellspacing_v: '0',
                cellspacing_h: '0',
              },
              adaptation: '1',
            },
          },
          {
            type: 0,
            format_id: 2,
            params: {
              img: {
                wide: '1',
                style: '',
                width: '250',
                border: {
                  size: '',
                  type: 'solid',
                  color: '',
                  radius: '0',
                },
                textIndent: '5',
              },
              desc: {
                font: {
                  name: 'Sans-serif',
                  size: '20',
                  color: '555555',
                  weight: 'bold',
                },
                text: {
                  decoration: 'none',
                },
                style: '{\r\n    transition:all .2s linear;\r\n}\r\n',
              },
              text: {
                align: 'left',
                padding: '5',
                position: '1',
              },
              fixed: '0',
              table: {
                cell: {
                  color: 'FFFFFF',
                  style: '',
                  border: {
                    size: '1',
                    type: 'solid',
                    color: 'e5e5e5',
                    radius: '0',
                  },
                  padding: '5',
                },
                rows: '4',
                style: '',
                width: '100',
                height: '',
                margin: ['0', '0', '0', '0'],
                bgColor: '',
                columns: '1',
                padding: ['0', '0', '0', '0'],
                widthType: '1',
                heightType: '1',
                cellspacing_v: '7',
                cellspacing_h: '0',
              },
              adaptation: '0',
            },
          },
          {
            type: 0,
            format_id: 3,
            params: {
              img: {
                wide: '1',
                style: '',
                width: '140',
                border: {
                  size: '0',
                  type: 'solid',
                  color: 'ffffff',
                  radius: '0',
                },
                textIndent: '6',
              },
              desc: {
                font: {
                  name: 'Sans-serif',
                  size: '15',
                  color: '555555',
                  weight: 'normal',
                },
                text: {
                  decoration: 'none',
                },
                style: '{\r\n    line-height:20px;\r\n}',
              },
              text: {
                align: 'left',
                padding: '3',
                position: '0',
              },
              fixed: '0',
              table: {
                cell: {
                  color: 'ffffff',
                  style: '',
                  border: {
                    size: '1',
                    type: 'solid',
                    color: 'e5e5e5',
                    radius: '3',
                  },
                  padding: '3',
                },
                rows: '6',
                style: '::before{\r\n    content:\'\';\r\n    font-size:16px;\r\n    font-family:sans-serif;\r\n    font-weight:bold;\r\n    text-transform:uppercase;\r\n    color:#000000;\r\n    display:block;\r\n    padding-bottom:.5em;\r\n    border-bottom:2px solid #7367f0;\r\n    margin-bottom:.75em;\r\n}\r\n',
                width: '100',
                height: '0',
                margin: ['0', '0', '0', '0'],
                bgColor: '',
                columns: '1',
                padding: ['0', '0', '0', '0'],
                widthType: '1',
                heightType: '1',
                cellspacing_v: '3',
                cellspacing_h: '0',
              },
              adaptation: '0',
            },
          },
          {
            type: 0,
            format_id: 4,
            params: {
              img: {
                wide: '1',
                style: '',
                width: '280',
                border: {
                  size: '0',
                  type: 'solid',
                  color: 'ffffff',
                  radius: '0',
                },
                textIndent: '3',
              },
              desc: {
                font: {
                  name: 'Sans-serif',
                  size: '15',
                  color: '000000',
                  weight: 'bold',
                },
                text: {
                  decoration: 'none',
                },
                style: '{\r\n    position:absolute;\r\n    width:100%;\r\n    top:129px;\r\n    left:0;\r\n    background:#FFFFFF;\r\n    padding:7px;\r\n    box-sizing:border-box;\r\n    transition:all .2s linear;\r\n}',
              },
              text: {
                align: 'left',
                padding: '5',
                position: '1',
              },
              fixed: '1',
              table: {
                cell: {
                  color: 'FFFFFF',
                  style: ':hover .pnx-desc{\r\n    color:#900022;\r\n}\r\n',
                  border: {
                    size: '0',
                    type: 'solid',
                    color: 'E3E3E3',
                    radius: '0',
                  },
                  padding: '0',
                },
                rows: '3',
                style: '{\r\n    margin:auto;\r\n}',
                width: '300',
                height: '600',
                margin: ['0', '0', '0', '0'],
                bgColor: '',
                columns: '1',
                padding: ['0', '0', '0', '0'],
                widthType: '0',
                heightType: '0',
                cellspacing_v: '5',
                cellspacing_h: '0',
              },
              adaptation: '0',
            },
          },
          {
            type: 1,
            format_id: 5,
            params: {
              img: {
                wide: '1',
                style: '',
                width: '320',
                border: {
                  size: '0',
                  type: 'solid',
                  color: 'ffffff',
                  radius: '0',
                },
                textIndent: '3',
              },
              desc: {
                font: {
                  name: 'Sans-serif',
                  size: '23',
                  color: '000000',
                  weight: 'bold',
                },
                text: {
                  decoration: 'none',
                },
                style: '{\r\n    line-height:1.1;\r\n    padding:2px 0 0 0;\r\n    -webkit-font-smoothing:antialiased;\r\n}',
              },
              text: {
                align: 'left',
                padding: '5',
                position: '1',
              },
              fixed: '0',
              table: {
                cell: {
                  color: 'ffffff',
                  style: '',
                  border: {
                    size: '1',
                    type: 'solid',
                    color: 'e5e5e5',
                    radius: '0',
                  },
                  padding: '5',
                },
                rows: '1',
                style: '',
                width: '100',
                height: '280',
                margin: ['0', '0', '0', '0'],
                bgColor: 'ffffff',
                columns: '1',
                padding: ['0', '0', '0', '0'],
                widthType: '1',
                heightType: '0',
                cellspacing_v: '0',
                cellspacing_h: '0',
              },
              adaptation: '0',
            },
          },
          {
            type: 1,
            format_id: 6,
            params: {
              img: {
                wide: '1',
                style: '',
                width: '300',
                border: {
                  size: '0',
                  type: 'solid',
                  color: 'ffffff',
                  radius: '0',
                },
                textIndent: '8',
              },
              desc: {
                font: {
                  name: 'Sans-serif',
                  size: '20',
                  color: '000000',
                  weight: 'bold',
                },
                text: {
                  decoration: 'none',
                },
                style: '{\r\n    position:absolute;\r\n    width:100%;\r\n    /*top:140px;\r\n    */\r\nbottom:0;\r\n    left:0;\r\n    background:#FFFFFF;\r\n    padding:3px 0px;\r\n    box-sizing:border-box;\r\n    transition:all .2s linear;\r\n}',
              },
              text: {
                align: 'center',
                padding: '0',
                position: '1',
              },
              fixed: '0',
              table: {
                cell: {
                  color: 'ffffff',
                  style: '',
                  border: {
                    size: '0',
                    type: 'solid',
                    color: 'e5e5e5',
                    radius: '0',
                  },
                  padding: '0',
                },
                rows: '2',
                style: '',
                width: '100',
                height: '450',
                margin: ['0', '0', '0', '0'],
                bgColor: 'ffffff',
                columns: '2',
                padding: ['0', '0', '0', '0'],
                widthType: '1',
                heightType: '0',
                cellspacing_v: '5',
                cellspacing_h: '5',
              },
              adaptation: '1',
            },
          },
          {
            type: 1,
            format_id: 7,
            params: {
              img: {
                wide: '1',
                width: '145',
                border: {
                  size: '0',
                  type: 'solid',
                  color: 'ffffff',
                  radius: '0',
                },
                textIndent: '10',
              },
              desc: {
                font: {
                  name: 'Sans-serif',
                  size: '14',
                  color: '555555',
                  weight: 'bold',
                },
                text: {
                  decoration: 'none',
                },
                style: '{\r\n    padding-top:5px;\r\n    padding-bottom:5px;\r\n}',
              },
              text: {
                align: 'left',
                padding: '5',
                position: '0',
              },
              fixed: '0',
              table: {
                cell: {
                  color: 'ffffff',
                  style: '',
                  border: {
                    size: '1',
                    type: 'solid',
                    color: 'e5e5e5',
                    radius: '0',
                  },
                  padding: '4',
                },
                rows: '4',
                style: '',
                width: '100',
                height: '440',
                margin: ['0', '0', '0', '0'],
                bgColor: 'ffffff',
                columns: '1',
                padding: ['0', '0', '0', '0'],
                widthType: '1',
                heightType: '0',
                cellspacing_v: '2',
                cellspacing_h: '2',
              },
              adaptation: '0',
            },
          },
        ],
        fonts: [
          'Sans-serif',
          'Roboto',
          'Verdana',
          'Arial',
          'Helvetica',
        ],
        imageWidths: [
          '300',
          '260',
          '230',
          '200',
          '180',
          '170',
          '160',
          '150',
          '140',
          '130',
          '120',
          '110',
          '100',
          '90',
          '80',
          '70',
          '65',
          '60',
          '50',
        ],
        rowsColsOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
        typeBorderOptions: [
          { value: 'solid', text: 'Solid' },
          { value: 'dashed', text: 'Dashed' },
          { value: 'dotted', text: 'Dotted' },
        ],
        descLocationOptions: [
          { value: 1, text: 'builder.description_positions.under' },
          { value: 0, text: 'builder.description_positions.right' },
        ],
      },
    }
  },
  computed: {
    blockData() {
      if (this.widgetSettings !== null) {
        return this.widgetSettings
      }
      return this.defaultBlockData
    },
    typeParamsLocal() {
      return this.builder.typeParamsOptions
        .map(t => ({ value: t.value, text: this.$i18n.t(t.text) }))
    },
    blockType() {
      return this.builder.blockTypeOptions
        .map(t => ({ value: t.value, text: this.$i18n.t(t.text) }))
    },
    pictureType() {
      return this.builder.pictureTypeOptions
        .map(t => ({ value: t.value, text: this.$i18n.t(t.text) }))
    },
    adaptationType() {
      return this.builder.adaptationTypeOptions
        .map(t => ({ value: t.value, text: this.$i18n.t(t.text) }))
    },
    descLocation() {
      return this.builder.descLocationOptions
        .map(t => ({ value: t.value, text: this.$i18n.t(t.text) }))
    },
    filtredFormat() {
      return this.builder.formats
        .filter(f => f.type === this.blockData.type)
        .map(formatItem => ({ value: formatItem.format_id, text: this.$i18n.t(`builder.formats.format${formatItem.format_id}`), params: formatItem.params }))
    },
    places() {
      return this.blockData.rows * this.blockData.cols
    },
  },
  watch: {
    blockData: {
      handler() {
        this.$nextTick().then(() => {
          this.$emit('setData', { ...this.widget, settings: this.blockData })
        })
      },
      deep: true,
    },
    'builder.blockFormat': {
      handler(newValue) {
        const idx = this.builder.formats.findIndex(f => f.format_id === newValue)
        this.setPreset(this.builder.formats[idx])
      },
      deep: false,
    },
    'blockData.type': {
      handler(newValue) {
        let selectedFormatData = null
        if (newValue === 1) {
          selectedFormatData = this.builder.formats.filter(f => f.format_id === 5)
          this.blockDataPreset = selectedFormatData[0].params
          this.builder.blockFormat = 5
          this.blockData.rows = 1
          this.blockData.cols = 1
        } else if (newValue === 0) {
          selectedFormatData = this.builder.formats.filter(f => f.format_id === 0)
          this.blockDataPreset = selectedFormatData[0].params
          this.builder.blockFormat = 0
          this.blockData.rows = 2
          this.blockData.cols = 3
        }
      },
      deep: false,
    },
    'blockData.rows': {
      handler() {
        this.$emit('widgetElements', this.places)
      },
      deep: false,
    },
    'blockData.cols': {
      handler() {
        this.$emit('widgetElements', this.places)
      },
      deep: false,
    },
  },
  created() {
    this.$emit('setData', { ...this.widget, settings: this.blockData })
    this.getUser()
  },
  methods: {
    async getUser() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || []
      this.getSiteData()
    },
    async getSiteData() {
      const responseData = await useJwt.getSite(this.$route.params.siteId)
      this.site = responseData.data.site || []
      this.setBreadcrumbs()
    },
    setBreadcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('page.menu.users'),
          to: '/users/',
        },
        {
          text: this.account.name,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: this.$t('sites.sites'),
          to: `/users/${this.$route.params.userId}/sites/`,
        },
        {
          text: this.site.title,
          to: `/users/${this.$route.params.userId}/sites/${this.$route.params.siteId}`,
        },
        {
          text: this.$t('page.widgets.create'),
          active: true,
        },
      ]
    },
    setPreset(preset) {
      const { params } = preset
      if (Object.keys(params).length) {
        this.blockData.type = parseInt(preset.type, 10)
        this.blockData.rows = parseInt(params.table.rows, 10)
        this.blockData.cols = parseInt(params.table.columns, 10)
        this.blockData.adaptation_type = parseInt(params.adaptation, 10)
        this.blockData.fixed = parseInt(params.fixed, 10)
        // this.blockData.table_padding = `${params.table.padding[0] || 0} ${params.table.padding[1] || 0} ${params.table.padding[2] || 0} ${params.table.padding[3] || 0}`
        this.blockData.table_padding = 0
        this.blockData.text_align = params.text.align
        this.blockData.table_width = parseInt(params.table.width, 10)
        this.blockData.table_width_units = parseInt(params.table.widthType, 10)
        this.blockData.table_height = params.table.height ? params.table.height : 0
        this.blockData.table_height_units = params.table.heightType ? parseInt(params.table.heightType, 10) : 0
        this.blockData.table_bg_color = params.table.bgColor ? `#${params.table.bgColor}` : '#ffffff'
        this.blockData.table_cellspacing_h = parseInt(params.table.cellspacing_h, 10)
        this.blockData.table_cellspacing_v = parseInt(params.table.cellspacing_v, 10)
        this.blockData.td_padding = parseInt(params.table.cell.padding, 10)
        this.blockData.td_bg_color = 'transparent'
        this.blockData.td_border_size = parseInt(params.table.cell.border.size, 10)
        this.blockData.td_border_type = params.table.cell.border.type
        this.blockData.td_border_color = params.table.cell.border.color ? `#${params.table.cell.border.color}` : ''
        this.blockData.td_border_radius = parseInt(params.table.cell.border.radius, 10)
        this.blockData.img_wide = parseInt(params.img.wide, 10)
        this.blockData.img_width = parseInt(params.img.width, 10)
        this.blockData.img_border_type = params.img.border.type
        this.blockData.img_border_size = params.img.border.size ? parseInt(params.img.border.size, 10) : 0
        this.blockData.img_border_color = params.img.border.color ? `#${params.img.border.color}` : 'transparent'
        this.blockData.img_text_indent = parseInt(params.img.textIndent, 10)
        this.blockData.img_border_radius = parseInt(params.img.border.radius, 10)
        this.blockData.desc_font = params.desc.font.name
        this.blockData.desc_font_weight = params.desc.font.weight
        this.blockData.desc_text_padding = parseInt(params.text.padding, 10)
        this.blockData.desc_text_decorat = params.desc.text.decoration === 'none' ? 0 : 1
        this.blockData.desc_font_size = parseInt(params.desc.font.size, 10)
        this.blockData.desc_font_color = params.desc.font.color ? `#${params.desc.font.color}` : ''
        this.blockData.desc_position = parseInt(params.text.position, 10)
        this.blockData.style = `${params.desc.style ? `.pnx-desc${params.desc.style}` : ''}
        ${params.table.cell.style ? `.pnx-td${params.table.cell.style}` : ''}
        ${params.table.style ? `.pnx-div${params.table.style}` : ''}`
      }
    },
  },
}
</script>
<style scoped>
.btn-desc-font {
  min-width: 36px;
  margin-left: .5rem;
  text-align: center;
  padding-left: .5rem;
  padding-right: .5rem;
}
</style>
